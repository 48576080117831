<template>
 <div class="p-fluid">
    <div class="formgrid grid">
        <div class="field col-12 md:col-6 lg:col-3">
            <label for="fencing_outlet">Fencing Outlet</label>
            <InputText id="fencing_outlet"  type="number" v-model="valueFencingOutlet" :class="{ 'p-invalid': errorEdit.fencing_outlet }" :disabled="checkedFencingOutlet"/>
            <div class="field-checkbox mt-2">
                <Checkbox id="binaryFO" v-model="checkedFencingOutlet" :binary="true" @change="changeFencingOutlet()"/>
                <label for="binaryFO">Without Fencing</label>
            </div>
            <small class="p-invalid" style="color: red" v-if="errorEdit.fencing_outlet" >{{ errorEdit.fencing_outlet[0] }}</small >
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                <div>        
                    <Button :loading="loadingFencingOutlet" label="Update" icon="pi pi-save" class="p-button-primary" @click="update('1')"  v-if="permission.includes('update')"/>
                </div>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <!--  -->
                </span>
            </div>
        </div>
        <div class="field col-12 md:col-6 lg:col-3">
            <label for="fencing_db">Fencing DB</label>
            <InputText id="fencing_db"  type="number" v-model="valueFencingDB" :class="{ 'p-invalid': errorEdit.fencing_db }" :disabled="checkedFencingDB"/>
            <div class="field-checkbox  mt-2">
                <Checkbox id="binaryFDB" v-model="checkedFencingDB" :binary="true" @change="changeFencingDB()"/>
                <label for="binaryFDB">Without Fencing</label>
            </div>
            <small class="p-invalid" style="color: red" v-if="errorEdit.fencing_db" >{{ errorEdit.fencing_db[0] }}</small >
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                <div>        
                    <Button :loading="loadingFencingDB" label="Update" icon="pi pi-save" class="p-button-primary" @click="update('3')" v-if="permission.includes('update')"/>
                </div>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <!--  -->
                </span>
            </div>
        </div>
    </div>
 </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            
            itemFencingOutlet: null,
            loadingFencingOutlet: false,
            valueFencingOutlet: null,
            checkedFencingOutlet: false,

            itemFencingDB: null,
            loadingFencingDB: false,
            valueFencingDB: null,
            checkedFencingDB: false,

            setting_value: null,
            without_fencing: null,
        }
    },
    created(){
        this.getItem('1');
        this.getItem('3');
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    computed:{
        ...mapGetters(['errorEdit']),

		...mapGetters("auth", ["permission"]),
    },
    methods: {
        changeFencingOutlet(){
            if(this.checkedFencingOutlet == true){
                this.valueFencingOutlet = null;
            }else{
                this.valueFencingOutlet = this.itemFencingOutlet.setting_value == '9999' ? null : this.itemFencingOutlet.setting_value;
            }
        },
        changeFencingDB(){
            if(this.checkedFencingDB == true){
                this.valueFencingDB = null;
            }else{
                this.valueFencingDB = this.itemFencingDB.setting_value == '9999' ? null : this.itemFencingDB.setting_value;
            }
        },
        update(id){

            if(id == '1'){
                if(this.checkedFencingOutlet == false && (this.valueFencingOutlet == null || this.valueFencingOutlet == '' )){
                    alert("Please fill the field");
                    return
                }
                this.loadingFencingOutlet = true;
                this.without_fencing = this.checkedFencingOutlet;
                this.setting_value = this.checkedFencingOutlet == true ? '9999' : this.valueFencingOutlet;
                
            }else{
                if(this.checkedFencingDB == false && (this.valueFencingDB == null || this.valueFencingDB == '' )){
                    alert("Please fill the field");
                    return
                }
                this.loadingFencingDB = true;
                this.without_fencing = this.checkedFencingDB;
                this.setting_value = this.checkedFencingDB == true ? '9999' : this.valueFencingDB;
            }

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/setting/update',
                data: {
                    "setting_id" : id,
                    "setting_value" : this.setting_value,
                    "without_fencing" : this.without_fencing,
                },
            })
            .then((res) => {
                this.loadingFencingOutlet = false;
                this.loadingFencingDB = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Updated', life: 3000,});
                    this.getItem(id);
                    this.clearItem();
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingFencingOutlet = false;
                this.loadingFencingDB = false;
                // this.$store.commit('setErrorEdit', err?.response?.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Update', life: 3000,});
            });

        },
        clearItem(){
            this.setting_value= null;
            this.without_fencing= null;
        },
        getItem(id) {
            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/setting',
                params: {
                    "setting_id": id,
                }
            })
            .then(res => {

                if (res) {
                    this.$store.commit('setErrors', {});

                    if(id == '1'){
                        this.itemFencingOutlet = res.data.data;
                        this.valueFencingOutlet = this.itemFencingOutlet.setting_value == '9999' ? null : this.itemFencingOutlet.setting_value;
                        this.checkedFencingOutlet = this.itemFencingOutlet.setting_value == '9999' ? true : false;
                    }else{
                        this.itemFencingDB = res.data.data;
                        this.valueFencingDB = this.itemFencingDB.setting_value == '9999' ? null : this.itemFencingDB.setting_value;
                        this.checkedFencingDB = this.itemFencingDB.setting_value == '9999' ? true : false;
                    }
                }
            },(this))
            .catch((err) => {
                console.log(err);
            });
        }
    },
}
</script>