<template>
  <div class="grid" v-if="permission.includes('read')">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Error :errors="errors"/>

                <Fieldset legend="Global Settings" :toggleable="false" :collapsed="false" class="mb-3">
                    <Settings ref="settings"/>
                </Fieldset>
            </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Settings from '../../components/master/settings/Settings.vue';
import Error from '../../components/Error.vue';

export default {
    components: {
		'Settings': Settings,
		'Error': Error,
	},
    data() {
        return {
        }
    },
    mounted() {
        this.$store.commit('setErrors', {});
    },
    watch: {
    },
    computed:{
        ...mapGetters(['errors']),

		...mapGetters("auth", ["permission"]),
    },
    methods: {
    }
}
</script>
